/* <!--====== Bootstrap css ======--> */
@import url("../assets/css/bootstrap.min.css");
/* <!--====== Slick Slider ======--> */
@import url("../assets/css/slick.min.css");
/* <!--====== Magnific ======--> */
@import url("../assets/css/magnific-popup.min.css");
/* <!--====== Nice Select ======--> */
@import url("../assets/css/nice-select.min.css");
/* <!--====== Animate CSS ======--> */
@import url("../assets/css/animate.min.css");
/* <!--====== Font Awesome ======--> */
@import url("../assets/fonts/fontawesome/css/all.min.css");
/* <!--====== Flaticon ======--> */
@import url("../assets/fonts/flaticon/flaticon.css");
/* <!--====== Spacing Css ======--> */
@import url("../assets/css/spacing.min.css");
/* <!--====== Main Css ======--> */
@import url("../assets/css/style.css");
/* <!--====== Responsive CSS ======--> */
@import url("../assets/css/responsive.css");

html {
    scroll-behavior: smooth;
}
